import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; // 默认主题
// import "@/permission";
import BaiduMap from 'vue-baidu-map'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 't2M4bERgGow5WpwxqOS3kngLhxCkXyGR'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
