import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: () =>
      import('@/views/index'),
  },
  {
    path: '/case',
    component: () =>
      import('@/views/case'),
  },
  {
    path: '/caseItem',
    component: () =>
      import('@/views/caseItem'),
  },
  {
    path: '/news',
    component: () =>
      import('@/views/news'),
  },
  {
    path: '/newsInfo',
    component: () =>
      import('@/views/newsInfo'),
  },
  {
    path: '/newsItem1',
    component: () =>
      import('@/views/newsItem1'),
  },
  {
    path: '/newsItem2',
    component: () =>
      import('@/views/newsItem2'),
  },
  {
    path: '/newsItem3',
    component: () =>
      import('@/views/newsItem3'),
  },
  {
    path: '/newsItem4',
    component: () =>
      import('@/views/newsItem4'),
  },
  {
    path: '/partner',
    component: () =>
      import('@/views/partner'),
  },
  {
    path: '/me',
    component: () =>
      import('@/views/me'),
  },
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
