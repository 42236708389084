<template>
  <div id="app">
    <!-- <Navbar /> -->
    <router-view />
    <!-- <Footer /> -->
  </div>
</template>
<script>
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
export default {
  name: "App",
  components: {
    // Navbar,
    // Footer
  },
};
</script>
<style>
/*@font-face {
  font-family: "REGULAR";
  src: url("../src/assets/font/PINGFANG REGULAR.TTF") format("truetype");
}*/
body {
  margin: 0;
  overflow-x: hidden;
  font-family: REGULAR;
}
</style>
